'use strict';

class PieChartController {

  constructor($scope) {
    this.$scope = $scope;
    this.$scope.total = 600;
    $('.pie-chart').css('width',$scope.size +'px');
    $('.pie-chart').css('height',$scope.size +'px');
  }

  circleWidth() {
    return this.$scope.size/4;
  }

  strokeWidth() {
    return this.$scope.size/2;
  }

  circonference() {
    return 2 * Math.PI * this.$scope.size/4;
  }

  total() {
    var total=0;
    for (var i=0 ; i<this.$scope.portions.length ; i++) {
      total += this.$scope.portions[i].value;
    }
    return total;
  }

  getPourcentage(item) {
    return Math.round((100/this.$scope.total) * item.value) + '%';
  }

  getStrokeLength(index) {
    return this.circonference()*this.$scope.portions[index].value/this.$scope.total;
  }

  getAngle(index) {
    var angle = -90;
    for (var i=0 ; i<index ; i++) {
      angle += 360*this.$scope.portions[i].value/this.total();
    }
    return angle;
  }
}

angular.module('cpformplastApp')
  .controller('PieChartController', PieChartController);
